import React, { useEffect, useState } from "react";

import Header from "../widget/Header";
import Navbar from "../widget/Navbar";
import Footer from "../widget/Footer";

import ReactDatatable from "@mkikets/react-datatable";
import { ToastContainer, toast } from "react-toastify";

import moment from "moment";
import { getLevelIncomeReport, getLevelIncomeReportDate } from "../services/user";

export const LevelIncomeReport = () => {
  const [record, setRecord] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const columns = [
    {
      key: "Sr No.",
      text: "Sr. No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },
    {
      key: "registerId",
      text: "Register Id ",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },
    {
      key: "sponser_id",
      text: "Sponser Id",
      className: "sr_no.",
      align: "left",
      sortable: true,

    },

    
    {
      key: "level_income",
      text: "Level",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },{
      key: "roi_amount",
      text: "Amount",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },
    

    {
      key: "registerDate",
      text: "Register Date",
      className: "color",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{moment(record.created_at).format("LLL")}</>;
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Download",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {};

  const getLevelIncomeReports = async () => {
    let config = localStorage.getItem("jwtToken");

    const resp = await getLevelIncomeReport(config);

    if (resp.status) {
      setRecord(resp?.data);
    }
  };
  useEffect(() => {
    getLevelIncomeReports();
  }, []);

  const handleSearch = async () => {
    if (startDate > endDate) {
      toast.error("Start date cannot be later than end date");
      return;
    }
    if (startDate && endDate) {
      try {
        const token = localStorage.getItem("jwtToken");
        const data = { startDate, endDate };
        const res = await getLevelIncomeReportDate(data, token);

        if (res.status) {
          setRecord(res?.data);
        } else {
          setRecord([]);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    } else {
      toast.error("Please select both start and end dates");
    }
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="section-heading d-flex justify-content-between">
              <h2>
                <b>Level Income Report</b>
              </h2>
              <div>
                {" "}
                <w3m-button balance="hide" />
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12">
              <div className="d-flex mb-2 search_box_table">
                  <input
                    className="form-control form-control22 mr-3"
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                    placeholder="Start Date"
                  />
                  <input
                    className="form-control form-control22 mr-3"
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                    placeholder="End Date"
                    max={new Date().toISOString().substr(0, 10)}
                  />
                  <button className="btn btn_man_small" onClick={handleSearch}>
                    <i class="las la-search"></i>
                  </button>
                </div>
                <div className="product-list-outer">
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                    onPageChange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <ToastContainer
            limit={1}
            autoClose={3000}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            theme={"dark"}
          />
        </div>
      </div>
    </>
  );
};
