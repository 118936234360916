import * as opsService from "./Ops";

import { BaseUrl } from "../Constent/baseUrl";

const loginAdmin = async (data) => {
  let result = await opsService.postdata(BaseUrl + "/login-admin", data);
  return result;
};

const getUserList = async (data) => {
  let result = await opsService.getData(BaseUrl + "/user-list", data);
  return result;
};

const getUserListDate = async (data,token) => {
  let result = await opsService.postdata(BaseUrl + "/user-list-date", data,token);
  return result;
};
const getDepositReport = async (data) => {
  let result = await opsService.getData(BaseUrl + "/deposit-report", data);
  return result;
};
const getDepositReportDate = async (data,token) => {
  let result = await opsService.postdata(BaseUrl + "/deposit-report-date", data,token);
  return result;
};
const getDirectIncomeReport = async (data) => {
  let result = await opsService.getData(
    BaseUrl + "/direct-income-report",
    data
  );
  return result;
};
const getDirectIncomeReportDate = async (data,token) => {
  let result = await opsService.postdata(
    BaseUrl + "/direct-income-report-date",
    data,
    token
  );
  return result;
};

const getRoiIncomeReport = async (data) => {
  let result = await opsService.getData(BaseUrl + "/roi-income-report", data);
  return result;
};
const getRoiIncomeReportDate = async (data,token) => {
  let result = await opsService.postdata(BaseUrl + "/roi-income-report-date", data,token);
  return result;
};
const getLevelIncomeReport = async (data) => {
  let result = await opsService.getData(BaseUrl + "/level-income-report", data);
  return result;
};

const getLevelIncomeReportDate = async (data,token) => {
  let result = await opsService.postdata(BaseUrl + "/level-income-report-date", data,token);
  return result;
};

const getWithdrawalHistory = async (data) => {
  let result = await opsService.getData(BaseUrl + "/withdraw-history", data);
  return result;
};
const getWithdrawalHistoryDate = async (data,token) => {
  let result = await opsService.postdata(BaseUrl + "/withdraw-history-date", data,token);
  return result;
};
const withdrawalStatusManage = async (data, token) => {
  let result = await opsService.postdata(
    BaseUrl + "/status-update",
    data,
    token
  );
  return result;
};

const matchingReport = async (data, token) => {
  let result = await opsService.getData(
    BaseUrl + "/matching-report",
    data,
    token
  );
  return result;
};

const matchingReportDate = async (data, token) => {
  let result = await opsService.postdata(
    BaseUrl + "/matching-report-date",
    data,
    token
  );
  return result;
};

export {
  loginAdmin,
  getUserList,
  getDepositReport,
  getDirectIncomeReport,
  getRoiIncomeReport,
  getLevelIncomeReport,
  getWithdrawalHistory,
  withdrawalStatusManage,
  matchingReport,
  getUserListDate,
  getDepositReportDate,
  getDirectIncomeReportDate,
  getRoiIncomeReportDate,
  getLevelIncomeReportDate,
  matchingReportDate,
  getWithdrawalHistoryDate
};
