import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { UserManagement } from "./components/UserManagement";
import Login from "./components/Login";
import { DepositReport } from "./components/DepositReport";
import { useAuth } from "./AuthContext";
import Dashboard from "./components/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import { SignupUser } from "./components/SignupUser";
import { RoiReport } from "./components/RoiReport";
import { LevelIncomeReport } from "./components/LevelIncomeReport";
import { MatchingIncomeReport } from "./components/MatchingIncomeReport";
import { DirectIncome } from "./components/DirectIncome";
import { WithdrawalReport } from "./components/WithdrawalReport";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
function App() {

  toastr.options = {
    positionClass: "toast-top-right",
    hideDuration: 500,
    timeOut: 5000,
  };
  const { login } = useAuth();
  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      // login();
    }
  }, [login]);
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={<ProtectedRoute component={<Dashboard />} />}
          />
          <Route
            path="/user-management"
            element={<ProtectedRoute component={<UserManagement />} />}
          />

          <Route
            path="/deposit-report"
            element={<ProtectedRoute component={<DepositReport />} />}
          />
          
          <Route
            path="/roi-report"
            element={<ProtectedRoute component={<RoiReport />} />}
          />
          <Route
            path="/level-income-report"
            element={<ProtectedRoute component={<LevelIncomeReport />} />}
          />
          <Route
            path="/matching-income-report"
            element={<ProtectedRoute component={<MatchingIncomeReport />} />}
          />
          <Route
            path="/direct-income-report"
            element={<ProtectedRoute component={<DirectIncome />} />}
          />
          <Route
            path="/withdrawal-report"
            element={<ProtectedRoute component={<WithdrawalReport />} />}
          />
        </Routes>
      </Router>
      <ToastContainer
        limit={1}
        autoClose={2000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </div>
  );
}

export default App;
