import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import Header from "../widget/Header";
import Navbar from "../widget/Navbar";
import Footer from "../widget/Footer";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { dashboardData, manualCron } from "../services/dashboard";
import {} from "../services/user";

function Dashboard() {
  const [totalUser, setTotalUser] = useState("");
  const [DirectIncome, setDirectIncome] = useState("");
  const [RoiLevelIncome, setRoiLevelIncome] = useState("");
  const [RoiIncome, setRoiIncome] = useState("");
  const [totalDeposit, setTotalDeposit] = useState("");
  const [totalWithdrawal, setTotalWithdrawal] = useState("");
  const [totalReward, setTotalReward] = useState("");

  const [chainTypes, setSelectChainTypes] = useState("");
  const [offset, setSelectoffset] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [totalTransaction, setTotalTransaction] = useState("");

  const { login } = useAuth();

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      login();
    }
  }, [login]);

  useEffect(() => {
    const fetchData = async () => {
      const config = localStorage.getItem("jwtToken");
      const res = await dashboardData(config);
      if (res?.status) {
        setTotalUser(res?.TotalUser);
        setDirectIncome(Number(res?.DirectIncome).toFixed(4));
        setTotalTransaction(Number(res?.totalTransactions).toFixed(4));
        setRoiLevelIncome(Number(res?.RoiLevelIncome).toFixed(4));
        setRoiIncome(Number(res?.RoiIncome).toFixed(4));
        setTotalDeposit(Number(res?.totalDeposit).toFixed(4));
        setTotalWithdrawal(Number(res?.totalWithdrawal).toFixed(4))
        setTotalReward(Number(res?.totalMatchingIncome).toFixed(4))
      }
    };

    fetchData();
  }, []);


  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid p-0">
              <div className="dash-card-main">
                <div className="row">
                  <div className="col-md-3 col-sm-6 col-12 more-space">
                    <div className="card card-animate">
                      <Link to="/user-management">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="card-left">
                              <p className="fw-medium mb-0">Total users</p>
                            </div>
                            <div className="card-right">
                              <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title   rounded-circle fs-2">
                                  <img
                                    src="assets/images/menu-icon-light4.png"
                                    alt=""
                                    className="img-fluid"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="user-count">
                            <h2 className="m-0 ff-secondary fw-semibold">
                              <span className="counter-value" data-target={100}>
                                {totalUser ? totalUser : 0}
                              </span>
                            </h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12 more-space">
                    <div className="card card-animate">
                      <Link to="/direct-income-report">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="card-left">
                              <p className="fw-medium mb-0">
                                Total Direct Incomes
                              </p>
                            </div>
                            <div className="card-right">
                              <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title   rounded-circle fs-2">
                                  <img
                                    src="assets/images/menu-icon-light1.png"
                                    alt=""
                                    className="img-fluid"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="total-stack">
                            <h2 className="m-0 ff-secondary fw-semibold">
                              <span className="counter-value" data-target={10}>
                                {DirectIncome ? DirectIncome : 0}
                              </span>
                            </h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12 more-space">
                    <div className="card card-animate">
                      <Link to="/level-income-report">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="card-left">
                              <p className="fw-medium mb-0">
                                Total Level Incomes
                              </p>
                            </div>
                            <div className="card-right">
                              <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title  rounded-circle fs-2">
                                  <img
                                    src="assets/images/menu-icon-light1.png"
                                    alt=""
                                    className="img-fluid"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="total-stack">
                            <h2 className="m-0 ff-secondary fw-semibold">
                              <span className="counter-value" data-target={10}>
                                {RoiLevelIncome ? RoiLevelIncome : 0}
                              </span>
                            </h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12 more-space">
                    <div className="card card-animate">
                      <Link to="/matching-income-report">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="card-left">
                              <p className="fw-medium mb-0">
                                Total Reward Income
                              </p>
                            </div>
                            <div className="card-right">
                              <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title  rounded-circle fs-2">
                                  <img
                                    src="assets/images/menu-icon-light1.png"
                                    alt=""
                                    className="img-fluid"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="total-stack">
                            <h2 className="m-0 ff-secondary fw-semibold">
                              <span className="counter-value" data-target={10}>
                                {totalReward ? totalReward : 0}0
                              </span>
                            </h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12 more-space">
                    <div className="card card-animate">
                      <Link to="/roi-report">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="card-left">
                              <p className="fw-medium mb-0">Total Roi Income</p>
                            </div>
                            <div className="card-right">
                              <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title  rounded-circle fs-2">
                                  <img
                                    src="assets/images/menu-icon-light1.png"
                                    alt=""
                                    className="img-fluid"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="total-stack">
                            <h2 className="m-0 ff-secondary fw-semibold">
                              <span className="counter-value" data-target={10}>
                                {RoiIncome ? RoiIncome : 0}
                              </span>
                            </h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12 more-space">
                    <div className="card card-animate">
                      <Link to="/deposit-report">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="card-left">
                              <p className="fw-medium mb-0">Total Deposit</p>
                            </div>
                            <div className="card-right">
                              <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title rounded-circle fs-2">
                                  <img
                                    src="assets/images/menu-icon-light1.png"
                                    alt=""
                                    className="img-fluid"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="total-stack">
                            <h2 className="m-0 ff-secondary fw-semibold">
                              <span className="counter-value" data-target={10}>
                                {totalDeposit ? totalDeposit : 0}
                              </span>
                            </h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12 more-space">
                    <div className="card card-animate">
                      <Link to="/withdrawal-report">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="card-left">
                              <p className="fw-medium mb-0">Total Withdrawal</p>
                            </div>
                            <div className="card-right">
                              <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title  rounded-circle fs-2">
                                  <img
                                    src="assets/images/menu-icon-light1.png"
                                    alt=""
                                    className="img-fluid"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="total-stack">
                            <h2 className="m-0 ff-secondary fw-semibold">
                              <span className="counter-value" data-target={10}>
                                {totalWithdrawal ? totalWithdrawal : 0}
                              </span>
                            </h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
export default Dashboard;
