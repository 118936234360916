import React from "react";
import { Link } from "react-router-dom";

function Sidebar() {
  return (
    <div id="scrollbar" class="collapse">
      <div className="simplebar-content" style={{ padding: "0px" }}>
        <div className="container-fluid p-0">
          <ul className="navbar-nav" id="navbar-nav">
            <li className="nav-item">
              <Link
                to={"/dashboard"}
                className={
                  window.location.pathname === "/dashboard"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="mdi mdi-speedometer" />
                <span data-key="t-dashboards">Dashboard</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/user-management"}
                className={
                  window.location.pathname === "/user-management"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="las la-users"></i>
                <span data-key="t-dashboards">User-Management</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/deposit-report"}
                className={
                  window.location.pathname === "/deposit-report"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="las la-users"></i>
                <span data-key="t-dashboards">Deposit Report</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/direct-income-report"}
                className={
                  window.location.pathname === "/direct-income-report"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="mdi mdi-speedometer mdi-history" />
                <span data-key="t-dashboards"> Direct Income Report </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/roi-report"}
                className={
                  window.location.pathname === "/roi-report"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="mdi mdi-history" />
                <span data-key="t-dashboards">Roi Report</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/level-income-report"}
                className={
                  window.location.pathname === "/level-income-report"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="mdi mdi-history" />
                <span data-key="t-dashboards">Level Income Report </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/matching-income-report"}
                className={
                  window.location.pathname == "/matching-income-report"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="mdi mdi-history" />
                <span data-key="t-dashboards"> Reward Income Report</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to={"/withdrawal-report"}
                className={
                  window.location.pathname === "/withdrawal-report"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="mdi mdi-speedometer mdi-history" />
                <span data-key="t-dashboards">Withdrawal Request</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Sidebar;
